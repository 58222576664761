import { environment } from "../../../Environments/environment";
import { PostAsyncHelper } from "../../../redux/interfaces/types";

export const getBookingFromText = async (payload)=>{
    try{
       const url =   environment.apiUrl +'muv-ai/getBookingFromText';
       const response = await PostAsyncHelper(url, payload);
   
       console.log(response,59)
        if(response?.data?.status === "success"){
            return true;
        }
        return false;
    }
    catch{
       return false;
      
   
    }
   }