
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../../../redux/actions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'

import BookingItems from '../BookingItems'

import { HotelPaymentForm } from './PaymentForm'

import { clearHotelBooking } from './payment.slice'
import CongratulationsHotel from './BookingSuccess'
import "./PaymentPage.css"

const HotelPaymentContainer_ = ({ props, is_mobile, establishment, timelineStatus, hotelSearchForm,
    logo_height, app_menu_height, lang, uuid, setHotelBookingState, setOpenAuthModal, booking_info, fetchHotelBooking, booking_bundles
}) => {

    const [menu, setMenu] = React.useState("rooms")
    const [selectedType, setType] = React.useState(1)
    //console.log(props.match.params.establishmentPricelineID);
    const bookingSuccess =  useSelector(state => state.HotelBooking.bookingSuccess);
    var searchParams = new URLSearchParams(document.location.search.substring(1));

    searchParams.forEach((value, key) => {
        //console.log(value, key);
    });

    const hotel_id = searchParams.get("hotel_id")
    const check_in = searchParams.get("check_in")
    const check_out = searchParams.get("check_out")
    const rooms = searchParams.get("rooms")
    const adults = searchParams.get("adults")
    const ppn_book_bundle = searchParams.get("ppn_book_bundle")

    const booking_form_state = {
        hotel_id,
        ppn_book_bundle,
        check_in,
        check_out,
        rooms,
        adults
    }


    //const breadcrumb = establishment.breadcrumb
    //const booking_info = establishment.booking_info
    let padding_l = 15
    let padding_r = 15


    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : (timelineStatus ? "31.5" : "15")
        padding_r = is_mobile ? "1" : (timelineStatus ? "5" : "15")
    }

    const dispatch = useDispatch()

    //const headerHeight = (-Math.ceil(-app_menu_height / 2))
    //const menuOffset = -headerHeight - 90
    //const experiencesExisted = establishment && establishment.experiences && establishment.experiences.length > 0
    //const neighborhoodExisted = establishment && establishment.neighborhood 

    const signIn = () => {
        setOpenAuthModal(true)
    }

    React.useEffect(() => {
        setHotelBookingState(booking_form_state)       //HotelSearch.booking_info
        return () => {
            // dispatch(clearHotelBooking())
        }
    }, [])


    return (
        <React.Fragment>

            <div style={{ position: "relative" }}>
                <div style={{ flexGrow: 1 }}>
                    {!(uuid !== undefined && uuid !== null && uuid !== "") &&
                        <div style={{
                            paddingLeft: padding_l + "%",
                            paddingRight: padding_r + "%",
                            position: "relative",
                            //zIndex: "4",
                            backgroundColor: "var(--mainWhite)",
                            paddingTop: "15px",
                        }}>
                            <div style={{ font: is_mobile ? "12px/24px Futura Md BT" : "14px/24px Futura Md BT", color: "#000000", }}>Already a member?</div>
                            <div style={{ font: is_mobile ? "12px/24px Futura Lt BT" : "14px/24px Futura Lt BT", color: "#707070", display: "inline-block" }}>Sign in or register to save your selections and bookings to your account</div>
                            <div onClick={signIn} style={{ font: is_mobile ? "14px/24px Futura Hv BT" : "14px/24px Futura Hv BT", color: "#19BC9B", display: "inline-block", marginLeft: 30, cursor: "pointer" }}>
                                <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: "5px" }} />Sign in</div>

                        </div>}

                    <div style={{
                        paddingLeft: padding_l + "%",
                        paddingRight: padding_r + "%",
                        marginTop: "20px"
                    }}>


                        {!bookingSuccess && <div style={{
                            width:is_mobile? "100%": "calc(100% - 346px)",
                            display: "inline-block",
                            verticalAlign: "top",
                            padding:is_mobile? 10: 30
                        }} className="hotelPaymentForm">
                            <HotelPaymentForm selectedType={selectedType}
                            setType={setType}
                            is_mobile={is_mobile}/>

                        </div>}
                        {/* {bookingSuccess && <div style={{
                            marginTop: "20px",
                            width:is_mobile? "100%": "calc(100% - 346px)",
                            display: "inline-block",
                            verticalAlign: "top",
                            padding: 30
                        }} className="hotelPaymentForm">
                            <CongratulationsHotel />
                        </div>} */}

                        {booking_bundles !== undefined && booking_bundles !== null && booking_bundles.length > 0 &&
                            <div style={{
                                width:is_mobile? "100%":"346px",
                                margin: is_mobile? "0 auto": "0",
                                display:is_mobile?"block":"inline-block",
                                paddingTop: 30,
                                paddingBotom: 20
                            }} className="hotelPaymentCart">
                                 {booking_bundles !== undefined && booking_bundles !== null && booking_bundles.length > 0 &&
                                        booking_bundles.map((bundle, bidx) => {
                                            if (bundle && bidx === booking_bundles?.length - 1) {
                                                return (
                                                    <div style={{ marginBottom: 15 }} key={bidx+"cart"}  >
                                                       <BookingItems booking_bundles={booking_bundles}
                                                         showMultiple={false}
                                                       booking_bundle={bundle} 
                                                       formIndex={selectedType}/>
                                                    </div>
                                                )
                                            }
                                            return null
                                        })}
                            </div>}



                    </div>
                </div>




            </div>

        </React.Fragment >
    )
}


const mapStateToProps = state => {
    return {
        timelineStatus: state.FormBank.TimelineWizard.status,
        logo_height: state.Setting.appMenu.logo.height,
        sliderImages: state.FormBank.HotelSearch.establishment.sliderImages,
        lang: state.Setting.lang,
        uuid: state.Member.authModal.uuid,

        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        screenSize: state.Setting.htmlBody.bodyClientWidth,

        establishment: state.FormBank.HotelSearch.establishment,
        booking_info: state.FormBank.BookingPriceline.booking_info,
        booking_bundles: state.FormBank.BookingPriceline.booking_bundles,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
    }
}

const mapDispatchToProps = {
    fetchHotelEstablishment: Actions.fetchHotelEstablishment,
    fetchHotelBooking: Actions.fetchHotelBooking,
    setHotelEstablishmentId: Actions.setHotelEstablishmentId,
    setHotelBookingState: Actions.setHotelBookingState,
    setOpenAuthModal: Actions.setOpenAuthModal,
}

const HotelPaymentContainer = connect(mapStateToProps, mapDispatchToProps)(HotelPaymentContainer_)
export default HotelPaymentContainer
