import * as Actions from '../../../redux/actions'

import { member_area_router, menus_fields_auth_link } from "./RegExValidate"

import {
    AccountIcon,
} from '../Common/SVGBank';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import { Link, useHistory} from "react-router-dom"
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import React from 'react'
import { connect } from "react-redux";
import { menuListMemberAreaStyles } from "./ComponentsStylingBank"
import { profile_router } from "./RegExValidate"

/* menu list compo */
const MenuListMemberArea_ = ({closeTripTimelineWizard, setSwitchContainer, logoutMemberAccount, clearAllTimeline, setSwitchProfileContainer, fullName, switch_container, lang, setPrivateArea, isPrivateArea, font, fontColor, is_mobile, bodyClientWidth, logo_width, textColor, favorites, pluses }) => {
    const classes = menuListMemberAreaStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const history = useHistory()
    const location = history.location.pathname;
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handlerOnClick = (route) => {
        const url = "/muver/"+route;
        history.push(url.toLowerCase())
        setOpen(false);
    }
    const logoutMemberAccountHandler = () => {
        //console.log("MenuListMemberArea:logoutMemberAccountHandler")
        logoutMemberAccount()
        clearAllTimeline()
        closeTripTimelineWizard()
        setPrivateArea(false)
        setOpen(false)
        if(location !== "/home"){
            history.push("/home")
        }
    }
    const logoutMemberAreaAccountHandler = () => {
        //console.log("MenuListMemberArea:logoutMemberAreaAccountHandler")
        logoutMemberAccount()
        clearAllTimeline()
        closeTripTimelineWizard()
        setPrivateArea(false)
        setOpen(false)
        setSwitchContainer(member_area_router.home)
        if(location !== "/home"){
            history.push("/home")
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    const links = []
    menus_fields_auth_link.map((link) => {
        if (link.name === member_area_router.bucketList && pluses.length === 0) {

            // no dispaly
        } else if (link.name === member_area_router.favorites && favorites.length === 0) {
            // no dispaly
        } else {
            links.push(link)

        }
    })
    return (
        <div className={classes.root}>

            <div>
                <div
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-sort-trips' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}>
                    {is_mobile && <div style={{ outline: "0" }}><AccountIcon fill={textColor} /></div>}
                    <div className={classes.buttonText}
                        style={{
                            cursor: "pointer",
                            font: font,
                            color: fontColor,
                            paddingBottom: "0px",
                            paddingTop: "0px",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            textAlign: is_mobile ? "center" : "",
                            width: is_mobile ? logo_width + "px" : ""
                        }}
                    >
                        {fullName}
                    </div>
                </div>
                <ClickAwayListener onClickAway={handleClose}>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} className={classes.zIndex}
                        placement={bodyClientWidth > 500 ? "bottom-start" : "right-start"}
                        modifiers={{
                            flip: {
                                enabled: false,
                            },
                        }} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                            >
                                <Paper>
                                    <MenuList autoFocusItem={open} id="menu-list-sort-trips" >
                                        {links.map((menu, idx) => {
                                            return <MenuItem key={idx} className={switch_container === menu.name ? "btn btn-sm flex-wrap member_menu_top_btn_active" : "btn btn-sm flex-wrap member_menu_top_btn member_menu"} onClick={() => handlerOnClick(menu.name)}>
                                                {menu.display}
                                            </MenuItem>
                                        })}

                                        <Divider light />
                                        {
                                            isPrivateArea ?
                                                <MenuItem onClick={() => logoutMemberAreaAccountHandler()} style={{
                                                    font: "16px/16px Futura Md BT",
                                                    color: "#707070",
                                                }}>Sign out
                                                </MenuItem>
                                                :
                                                <MenuItem onClick={() => logoutMemberAccountHandler()} style={{
                                                    font: "16px/16px Futura Md BT",
                                                    color: "#707070",
                                                }}>Sign out
                                                </MenuItem>
                                        }
                                    </MenuList>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                </ClickAwayListener>
            </div>
        </div >
    );
}

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    logoutMemberAccount: Actions.logoutMemberAccount,
    clearAllTimeline: Actions.clearAllTimeline,
    closeTripTimelineWizard: Actions.closeTripTimelineWizard,
    setPrivateArea: Actions.setPrivateArea,
    setSwitchProfileContainer: Actions.setSwitchProfileContainer,

}

const mapStateToProps = (state) => {
    return {
        switch_container: state.Member.switch_container,
        isPrivateArea: state.Member.isPrivateArea,
        lang: state.Setting.lang,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,

        textColor: state.Setting.appMenu.textColor,
        logo_width: state.Setting.appMenu.logo.width,
        is_mobile: state.Setting.is_mobile,


        favorites: state.MemberActions.fetchFavoriteSets.favorites,
        pluses: state.MemberActions.fetchPlusSets.pluses,
    }
};
const MenuListMemberArea = connect(mapStateToProps, mapDispatchToProps)(MenuListMemberArea_);
export default MenuListMemberArea 