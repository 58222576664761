import {
    FETCH_AIRPORT_LIST_FAILURE,
    FETCH_AIRPORT_LIST_SUCCESS,
    FETCH_CITIES_LIST_FAILURE,
    FETCH_CITIES_LIST_SUCCESS,
    PL_FETCH_AIRPORT_LIST_FAILURE,
    PL_FETCH_AIRPORT_LIST_SUCCESS,
    PL_FETCH_CITIES_LIST_FAILURE,
    PL_FETCH_CITIES_LIST_SUCCESS,
    FETCH_DESTINATION_LIST_FAILURE,
    FETCH_DESTINATION_LIST_SUCCESS,
    FETCH_DISCOVERY,
    FETCH_DISCOVERY_ERROR,
    FETCH_HOMEPAGE_BUCKET_LIST_FAILURE,
    FETCH_HOMEPAGE_BUCKET_LIST_SUCCESS,
    FETCH_HOMEPAGE_FAILURE,
    FETCH_HOMEPAGE_GENERAL_INFO_FAILURE,
    FETCH_HOMEPAGE_GENERAL_INFO_SUCCESS,
    FETCH_HOMEPAGE_MUVER_PRESS_FAILURE,
    FETCH_HOMEPAGE_MUVER_PRESS_SUCCESS,
    FETCH_HOMEPAGE_MUVER_STYLE_FAILURE,
    FETCH_HOMEPAGE_MUVER_STYLE_SUCCESS,
    FETCH_HOMEPAGE_SLIDER_IMAGE_FAILURE,
    FETCH_HOMEPAGE_SLIDER_IMAGE_SUCCESS,
    FETCH_HOMEPAGE_SUCCESS,
    GetAsyncHelper,
    IS_FETCHED_DISCOVERY,
    SET_LOADER,
    SWITCH_DISCOVER_EXTENDER,
} from "../interfaces/types"

import Axios from 'axios';
import base_url from '../../Components/WebContent/Common/RegExValidate';

import { PRICELINE_SERVER, PRICELINE_REF_ID, PRICELINE_API_KEY } from "../../Components/WebContent/Common/RegExValidate"
import { environment } from "../../Environments/environment";

/*  get homepage data  */
export const getHomePage = (lang) => async dispatch => {
    try {
        const response = await Axios.get(environment.apiUrl +"home/main/" + lang)

        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_HOMEPAGE_SUCCESS,
            payload: response.data,
        })
        dispatch({
            type: SET_LOADER,
            payload: false
        })
        window.scrollTo(0, 0)
    } catch (error) {
        dispatch({
            type: FETCH_HOMEPAGE_FAILURE,
            payload: error.message
        })
    }
}

/*  get homepage bucket list  */
export const getHomeBucketList = (lang) => async dispatch => {
    try {
        const response = await Axios.get(environment.apiUrl +"home/getBucketlists/" + lang + "?limit=50&offset=0")

        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_HOMEPAGE_BUCKET_LIST_SUCCESS,
            payload: response.data.bucketList,
        })
    } catch (error) {
        dispatch({
            type: FETCH_HOMEPAGE_BUCKET_LIST_FAILURE,
            payload: error.message
        })
    }
}

/*  get homepage muver style  */
export const getHomePageMuverStyle = (lang) => async dispatch => {
    try {
        const response = await Axios.get(environment.apiUrl +"home/getmuverstyles/" + lang)

        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_HOMEPAGE_MUVER_STYLE_SUCCESS,
            payload: response.data,
        })
    } catch (error) {
        dispatch({
            type: FETCH_HOMEPAGE_MUVER_STYLE_FAILURE,
            payload: error.message
        })
    }
}


/*  get homepage muver press  */
export const getHomePageMuverPress = (lang) => async dispatch => {
    try {
        const response = await Axios.get(environment.apiUrl +"home/getmuvinthepress/" + lang)

        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_HOMEPAGE_MUVER_PRESS_SUCCESS,
            payload: response.data,
        })
    } catch (error) {
        dispatch({
            type: FETCH_HOMEPAGE_MUVER_PRESS_FAILURE,
            payload: error.message
        })
    }
}


/*  get general info muver press  */
export const getHomePageGeneralInfo = (lang) => async dispatch => {
    try {
        const response = await Axios.get(environment.apiUrl +"home/getHomeGeneralInfo/" + lang)

        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_HOMEPAGE_GENERAL_INFO_SUCCESS,
            payload: response.data,
        })
    } catch (error) {
        dispatch({
            type: FETCH_HOMEPAGE_GENERAL_INFO_FAILURE,
            payload: error.message
        })
    }
}

/*  get slider image muver press  */
export const getHomePageSliderImage = (lang) => async dispatch => {
    try {
        const response = await Axios.get(environment.apiUrl +'home/getHomeSliderImages/' + lang)

        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_HOMEPAGE_SLIDER_IMAGE_SUCCESS,
            payload: response.data.sliderImages,
        })
    } catch (error) {
        dispatch({
            type: FETCH_HOMEPAGE_SLIDER_IMAGE_FAILURE,
            payload: error.message
        })
    }
}


/*  get homepage destination list -search  */
export const getDestinationListTopBar = (query, lang, isRoadTrip) => async dispatch => {
    //console.log(`HomeAction getDestinationListTopBar isRoadTrip = ${isRoadTrip}`)
    const url = !isRoadTrip? environment.apiUrl +'muvsearch/destinationsearch/' + query + '/' + lang :
    environment.apiUrl +'muvsearch/destinationsearch/' + query + '/' + lang + "?tripTravelCategory[code]=road"

    //console.log("getDestinationListTopBar url : ")
    //console.log(url)

    try {
        const response = await Axios.get(url)
        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        //console.log(response.data)

        dispatch({
            type: FETCH_DESTINATION_LIST_SUCCESS,
            payload: response.data,
        })
    } catch (error) {
        dispatch({
            type: FETCH_DESTINATION_LIST_FAILURE,
            payload: error.message
        })
    }
}

/*  get cities from PriceLine -search  */
export const getPriceLineListTopBar = (query, lang, isRoadTrip) => async dispatch => {
    //console.log(`HomeAction getDestinationListTopBar isRoadTrip = ${isRoadTrip}`)
    const url = !isRoadTrip? environment.apiUrl +'muvsearch/destinationsearch/' + query + '/' + lang :
    environment.apiUrl +'muvsearch/destinationsearch/' + query + '/' + lang + "?tripTravelCategory[code]=road"

    //console.log("getDestinationListTopBar url : ")
    //console.log(url)

    try {
        //const response = await Axios.get(url)
        const response = await Axios.request({
            url: PRICELINE_SERVER+'/api/hotel/getAutoSuggestV2' ,
            method: 'get',
            params: {
              format: 'json2',
              refid: PRICELINE_REF_ID,
              api_key: PRICELINE_API_KEY
            },
        })

        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        //console.log(response.data)

        dispatch({
            type: FETCH_DESTINATION_LIST_SUCCESS,
            payload: response.data.getHotelAutoSuggestV2.results.result.cities,
        })
    } catch (error) {
        dispatch({
            type: FETCH_DESTINATION_LIST_FAILURE,
            payload: error.message
        })
    }
}


/*  get homepage cities list -search  */
export const getPriceLineCitiesList = (query) => async dispatch => {
    try {
        //const response = await Axios.get('http://stagingbooking.muvtravel.com/api/cities/' + query)

        const proxyData = {
            string: query,
            show_all_cities:false,
            get_hotels:true,
            get_cities:true,
            max_results:3,
            sort:"name"
        }

        const response = await Axios.request({
            //url: PRICELINE_SERVER+'/hotel/getAutoSuggestV2' ,
            url: `${environment.apiUrl}proxy/dispatchRequest`,
            method: 'get',
            params: {
                //format: 'json2',
                //refid: PRICELINE_REF_ID,
                //api_key: PRICELINE_API_KEY,
                //string: query,
                "proxyData[string]":query,
                "proxyData[show_all_cities]":true,
                "proxyData[combine_regions]":true,
                "proxyData[get_hotels]":true,
                "proxyData[get_airports]":true,
                // "proxyData[get_regions]":true,
                "proxyData[get_cities]":true,
                // "proxyData[spellcheck]":true,
                "proxyData[get_pois]":true,
                // "proxyData[max_results]":3,
                "proxyData[sort]":"name",
                proxyApiMethod:"GET",
                proxyApiProperty:"hotel",
                proxyApiEndpoint:"getAutoSuggestV2"
            },
        })

        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        
        if (response.data.ProxyResult.getHotelAutoSuggestV2.results.status !== "Success") {
            throw Error(response.data.ProxyResult.getHotelAutoSuggestV2.results.status);
        }

        const cities = response.data.ProxyResult.getHotelAutoSuggestV2.results.result.cities; 
        const pois = response.data.ProxyResult.getHotelAutoSuggestV2.results.result.pois; 
        const hotels = response.data.ProxyResult.getHotelAutoSuggestV2.results.result.hotels; 
        
        //let citiesArry = Object.values(cities).forEach(val => {return val});   // ES8 - is this supported?
        

        let citiesArry = [];
        let poisArray = [];
        if(cities){
            Object.keys(cities).forEach((key, index) => citiesArry.push(cities[key])); 
        }

        // if(pois && pois?.length > 0){
        //     poisArray = pois;
        //     poisArray= poisArray.map((val)=>{
        //         return {...val,
        //             cityid_ppn: val?.poiid_ppn,
        //             type:"city"
        //         }
        //     })
        //     citiesArry = [...citiesArry,...poisArray] 
        // }

        
        let hotelsArry = [];
        if(hotels){
            Object.keys(hotels).forEach((key, index) => hotelsArry.push(hotels[key]));
        }
       
        
 
        var payload = [...hotelsArry, ...citiesArry];

        dispatch({
            type: PL_FETCH_CITIES_LIST_SUCCESS,
            payload: payload,
        })
        // TODO dispatch set search bar criteria

    } catch (error) {
        dispatch({
            type: PL_FETCH_CITIES_LIST_FAILURE,
            payload: error.message
        })
    }
}

/*  get homepage cities list -search  */
export const getPriceLineAirportList = (query) => async dispatch => {
    try {
        const response = await Axios.get('http://stagingbooking.muvtravel.com/api/airports/' + query)

        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: PL_FETCH_AIRPORT_LIST_SUCCESS,
            payload: response.data,
        })
    } catch (error) {
        dispatch({
            type: PL_FETCH_AIRPORT_LIST_FAILURE,
            payload: error.message
        })
    }
}



/*  get homepage cities list -search  */
export const getCitiesList = (query) => async dispatch => {
    try {
        const response = await Axios.get('http://stagingbooking.muvtravel.com/api/cities/' + query)

        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
        dispatch({
            type: FETCH_CITIES_LIST_SUCCESS,
            payload: response.data,
        })
    } catch (error) {
        dispatch({
            type: FETCH_CITIES_LIST_FAILURE,
            payload: error.message
        })
    }
}


/*  get homepage airport list -search  */
export const getAirportList = (query) => async dispatch => {
    try {
       const response = await Axios.request({
        //url: PRICELINE_SERVER+'/hotel/getAutoSuggestV2' ,
        url: `${environment.apiUrl}proxy/dispatchRequest` ,
        method: 'get',
        params: {
            "proxyData[string]":query,
            "proxyData[airports]":true,
            "proxyData[cities]": true,
            proxyApiMethod:"GET",
            proxyApiProperty:"air",
            proxyApiEndpoint:"getAutoComplete"
        },
      })
    
    
      if(response.data.ProxyResult.getAirAutoComplete){
        const airports = response.data.ProxyResult.getAirAutoComplete.results.getSolr.results.data.airport_data; 
        let airportsArry = [];
        Object.keys(airports).forEach((key, index) => airportsArry.push(airports[key])); 
          dispatch({
            type: FETCH_AIRPORT_LIST_SUCCESS,
            payload: airportsArry,
        })
      }

        if (response.statusText !== "OK") {
            throw Error(response.statusText);
        }
      
    } catch (error) {
        dispatch({
            type: FETCH_AIRPORT_LIST_FAILURE,
            payload: error.message
        })
    }
}


/*  get discover data  */
export const fetchDiscoverySets = (lang) => async dispatch => {
    const api_url = environment.apiUrl +'home/getFooterDestinations'

    GetAsyncHelper(api_url).then(response => {
        if (response.statusText === "OK") {
            dispatch({
                type: FETCH_DISCOVERY,
                discover_sets: response.data.result,
            })
            dispatch({
                type: IS_FETCHED_DISCOVERY,
                is_fetched: true,
            })

        } else {
            dispatch({
                type: FETCH_DISCOVERY_ERROR,
                api_error: true
            })
        }
    })
}


export const setterExtenderDiscover = () => dispatch => {
    dispatch({
        type: SWITCH_DISCOVER_EXTENDER,
    })
}