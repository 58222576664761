import "./PublicTripTimeline.css"

import { DownArrowIcon, UpArrowIcon } from "../Common/SVGBank"
import { validate, validate_fields } from "../Common/RegExValidate"
import TimelineBookedHotel from '../Common/TimelineWizard/TimelineBookedHotel';
import { EstablishmentDescription } from '../Common/EstablishmentDescription'
import EstablishmentName from '../Common/Establishment/EstablishmentName'
import {
    Image,
} from 'react-bootstrap'
import { useHistory } from "react-router-dom"
import Rating from '@material-ui/lab/Rating'
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { image_boxing } from '../Common/RegExValidate'
import moment from 'moment'
import { time_format } from "../Common/RegExValidate"
import TripScheduleFlight from "../Common/Trip/TripScheduleFlight"
import { getTimelineFlights, getTimelineHotels } from "../Common/Trip/ActiveTrip.slice"
import MuverTripFlight from "./MuverTripFlight"
import { getFlightsListThunk } from "../FlightsPage/services/Flights.thunks"
import MuverTripHotel from "./MuverTripHotel"
import { updateOneclickFlight, updateOneclickHotel, updateOneclickSaved, updatePackageData, updatePackageFlights, updatePackageHotels } from "../FeaturePage/personalizedTrip/personalizedTrip.slice"
import { setActiveTripDestinationId, setTimeLineStatus } from "../../../redux/actions"
import MuverTripHotelSlider from "./MuverTripHotelSlider"
import TimelineTripHotel from "../Common/TimelineWizard/TimelineTripHotel";
import { BookingUpload } from "./BookingUpload";

/* timeline component */
const PublicTripTimeline_ = ({ openTimeline, setOpenTimeline, trip, uuid, screenSize, is_mobile, bodyClientWidth, logo_width }) => {
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const [destinationNumberIndex, setDestinationNumberIndex] = React.useState(0)
    const timelineHotels = useSelector(state => state.ActiveTrip.timelineHotels);
    const muvHotels = timelineHotels && timelineHotels.muvHotels ? timelineHotels.muvHotels : null;
    const timelineFlights = useSelector(state => state.ActiveTrip.timelineFlights);
    const hasSavedFlights = timelineFlights && timelineFlights.flightInfo.length > 0;

    const widthContainer = is_mobile ? Math.floor(bodyClientWidth * 0.98) : Math.floor(((bodyClientWidth - 17) * 0.83) - (logo_width * 2) - 40)
    const widthImage = widthContainer / 2
    const heightImage = widthImage / 2
    const marginLeft = is_mobile ? 0 : 150;
    const history = useHistory()

    const setClose = () => {
        setOpenTimeline(false)
        document.getElementById("public_trip_timeline").scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    const [tripDestinations, setTripDestinations] = React.useState([])

    const destinaitonRadioTimelineHandler = (num) => {
        setDestinationNumberIndex(num)
    }

    const dispatch = useDispatch()
    React.useEffect(() => {

        try {
            const tripDestinations = trip.tripDestinations


            if (tripDestinations !== undefined && tripDestinations.length > 0 && tripDestinations[0] !== undefined) {
                tripDestinations.sort((a, b) => parseFloat(a?.startingDayInTrip) - parseFloat(b?.startingDayInTrip));


                const backFillDestinations = tripDestinations?.map((dest) => {
                    if (!dest?.destinationDays) {
                        let backFillDays = dest?.spanningDays !== undefined &&
                            dest?.spanningDays.split(',')

                        backFillDays = backFillDays?.map((day, index) => {
                            return {
                                activityDay: day,
                                backFill: true,
                                activityIndex: index + 1
                            }
                        })
                        dest["destinationDays"] = backFillDays;
                    }
                    else {
                        let destinationDays = dest?.destinationDays?.sort((a, b) => parseFloat(a?.activityDay) - parseFloat(b?.activityDay));
                        const spanningDays = dest?.spanningDays;

                        if (spanningDays) {
                            destinationDays = destinationDays?.filter((day) => spanningDays?.includes(day?.activityDay));
                        }

                        dest["destinationDays"] = destinationDays;
                    }


                    return dest
                })
                setTripDestinations(backFillDestinations)
            }

            if (trip?.tripID) {
                dispatch(getTimelineHotels({
                    uuid: uuid,
                    tripId: trip?.tripID
                }))
                dispatch(getTimelineFlights({
                    uuid: uuid,
                    tripId: trip?.tripID
                }))
            }
        }
        catch {

        }

    }, [trip.tripDestinations])


    let day_container = 0
    let day_container_display = 0
    let time_container = 0
    let time_container_dispaly = 0



    const destinationFlights = timelineFlights && timelineFlights?.flightInfo?.length > 0 ?
        timelineFlights.flightInfo?.filter((flight) => flight?.trip_destinations_id === tripDestinations[destinationNumberIndex]?.td_id) : null;


    const destinationHotels = timelineHotels && timelineHotels?.customHotels?.length > 0 ?
        timelineHotels?.customHotels?.filter((hotel) => hotel?.tripDestinationId === tripDestinations[destinationNumberIndex]?.td_id) : null;


    const destinationMuvHotels = timelineHotels && timelineHotels?.muvHotels?.length > 0 ?
        timelineHotels?.muvHotels?.filter((hotel) => hotel?.tripDestinationId === tripDestinations[destinationNumberIndex]?.td_id) : null;


    const addActivity = (activity) => {
        if (tripDestinations && tripDestinations?.length > 0) {
            const destination = tripDestinations[destinationNumberIndex];
            const url = "/destination/" + destination?.partialUri + "/" + 1 + "/" + destination?.id;

            dispatch(setTimeLineStatus(true))

            // dispatch(setActiveTripDestinationId(destination,String(activity?.activityDay), String(activity?.activityIndex) ))

            history.push(url.toLowerCase());
        }
    }




    ////one click booking
    React.useEffect(() => {

        try {
            let flights = timelineFlights, hotels = timelineHotels;
            if (flights?.flightInfo) {
                flights = flights?.flightInfo?.map((flight) => { return { ...flight, type: "flight" } })
            }
            if (hotels?.customHotels) {
                hotels = hotels?.customHotels?.map((hotel) => { return { ...hotel, type: "hotel" } })
            }

            dispatch(updatePackageData(hotels?.concat(flights)))

        }
        catch {

        }
    }, [timelineFlights, timelineHotels])


    const height = 335;



    return (
        <div className="padding_left_right_white">
            <div className="d-flex padding_left_150_timeline padding_right_20_timeline row"
                style={{ marginLeft: "0px", marginRight: "0px" }} id={"public_trip_timeline"}>
                {tripDestinations !== undefined && tripDestinations.length > 0 &&
                    tripDestinations.map((destination, index) => {
                        return (
                            <React.Fragment key={index}>

                                <div key={index}
                                    className={destinationNumberIndex === index ? "bg_color_btn_white destination_white_active col-lg-2 col-md-3 col-sm-6 text-center" : "bg_color_btn_white col-lg-2 col-md-3 col-sm-6 text-center"}
                                    onClick={() => destinaitonRadioTimelineHandler(index)}>
                                    <div className="futura_md_bt_18_14 box_1 text-center d-inline-block p-3">{destination.title}</div></div>

                            </React.Fragment>
                        )
                    })
                }
            </div>

            <div style={{
                marginLeft: marginLeft,
                width: is_mobile ? "100%" : "81%",
                marginTop:20,
                marginBottom:20
            }}>
                {/* <BookingUpload/> */}
            </div>

            {/* departure */}
            {destinationFlights && destinationFlights?.length > 0 &&

                <div>
                    <div style={{
                        font: "22px/16px Futura Md BT", padding: 25, background: "#F5F7F6", marginTop: 20,
                        width: is_mobile ? "100%" : "81%",
                        marginLeft: marginLeft,
                    }} >YOUR FLIGHT SUGGESTION</div>
                    {destinationFlights.map((flight, index) => {
                        const isReturnFlight = hasSavedFlights && timelineFlights.flightInfo.length > 1 && index === timelineFlights.flightInfo.length - 1;
                        if (index === 0) {
                            dispatch(updateOneclickFlight(flight))
                        }

                        if (!flight?.return_leg_from_trip_destination_id && flight?.flight_json) {
                            const isRoundTrip = flight?.search_type === "round_trip";
                            return (<div key={index}>
                                <div style={{}}>

                                    <MuverTripFlight flight={flight}
                                        sliceIndex={isRoundTrip ? 0 : null}
                                        destinationFlights={destinationFlights}
                                        tripDestinations={tripDestinations}
                                        currentDestIndex={destinationNumberIndex} />
                                </div>
                            </div>)
                        }
                    })
                    }
                </div>
            }


            {destinationHotels && destinationHotels?.length > 0 &&

                <div>
                    <div style={{
                        font: "22px/16px Futura Md BT", padding: 25, background: "#F5F7F6", marginTop: 20,
                        width: is_mobile ? "100%" : "81%",
                        marginLeft: marginLeft,
                    }} >YOUR STAY SUGGESTION</div>

                    {
                        destinationHotels.length === 1 && destinationHotels.map((hotel, index) => {

                            if (index === 0) {
                                dispatch(updateOneclickHotel(hotel))
                            }

                            return (<div key={index + "hotelDest"} id={"hotel_" + hotel?.tripDestinationId}>

                                <div style={{}}>

                                    <MuverTripHotel hotel={hotel} />


                                </div>

                            </div>)

                        })}

                </div>
            }
            <div style={{
                marginLeft: marginLeft,
                overflowX: "auto",
                overflowY: "hidden",
                display: "flex"

            }}>
                {
                    destinationHotels?.length > 1 && destinationHotels.map((hotel, index) => {

                        if (index === 0) {
                            dispatch(updateOneclickHotel(hotel))
                        }

                        return (<div key={index + "hotelDest"} id={"hotel_" + hotel?.tripDestinationId}
                            style={{}}>



                            <TimelineTripHotel hotel={hotel} />


                        </div>)

                        ///slider
                    })}

            </div>

            {destinationMuvHotels && destinationMuvHotels?.length > 0 &&

                <div>
                    <div style={{
                        font: "22px/16px Futura Md BT", padding: 25, background: "#F5F7F6", marginTop: 20,
                        width: is_mobile ? "100%" : "81%",
                        marginLeft: marginLeft,
                    }} >YOUR STAY SUGGESTION</div>

                    {destinationMuvHotels?.length === 1 && destinationMuvHotels.map((hotel, index) => {


                        return (<div key={index + "fullwidth"} id={"hotel_" + hotel?.tripDestinationId}>

                            <div style={{}}>

                                <MuverTripHotel hotel={hotel} />


                            </div>

                        </div>)



                    })}

                    <div style={{
                        marginLeft: marginLeft,
                        overflowX: "auto",
                        overflowY: "hidden",
                        display: "flex"
                    }}>
                        {
                            destinationMuvHotels?.length > 1 && destinationMuvHotels.map((hotel, index) => {

                                if (index === 0) {
                                    dispatch(updateOneclickHotel(hotel))
                                }

                                return (<div key={index + "hotelDest"} id={"hotel_" + hotel?.tripDestinationId}>



                                    <TimelineTripHotel hotel={hotel} />




                                </div>)


                            })}

                    </div>

                </div>}

            <div className="content_container">
                <div className="steps_timeline_hm text-left padding_left_150_timeline padding_right_20_timeline">
                    {tripDestinations[destinationNumberIndex]?.destinationDays !== undefined && tripDestinations[destinationNumberIndex]?.destinationDays.length > 0 &&

                        tripDestinations[destinationNumberIndex].destinationDays.map((activity, idx) => {
                            if (day_container !== activity.activityDay && validate(activity.activityDay, validate_fields.disallow_comma)) {
                                day_container = activity.activityDay
                                day_container_display = 1
                            } else {
                                day_container_display = 0
                            }
                            if (activity.activityStartTime !== undefined && activity.activityNoTime === "0") {
                                time_container = moment(activity.activityStartTime, "HH:mm:ss").format(time_format.hours_12)
                                time_container_dispaly = 1
                            } else {
                                time_container_dispaly = 0
                            }


                            const dayDate = trip?.tripStartDate ? moment(trip?.tripStartDate)?.add(Number(activity?.activityDay) - 1, "days")?.format("ddd, MMM D") : "Day " + activity.activityDay;
                            return (
                                <React.Fragment key={idx}>
                                    {(idx < 3) || openTimeline ?
                                        <div className={(idx !== tripDestinations[destinationNumberIndex].destinationDays.length - 1) ?
                                            (day_container_display === 1 && idx !== 0 ? "step_day padding_bottom_20 padding_top_20" : "step padding_bottom_20") :
                                            (day_container_display === 1 && idx !== 0 ? "step_last padding_bottom_20 padding_top_20" : "padding_bottom_20")
                                        } >
                                            <div className={tripDestinations[destinationNumberIndex].destinationDays.length > 1 ? "title_hm" : ""} style={{ position: "relative" }}>

                                                {day_container_display === 1 ?
                                                    <div className={idx !== 0 ? "day" : "first_day"}>
                                                        {/* {trip.noDate === "1" ? "Day " + activity.activityDay :
                                                            <div>
                                                                <div className="text-center text_main_blue" style={{ font: screenSize > 1024 ? "14px/14px Futura Hv BT" : "7px/7px Futura Hv BT", }}>{moment(trip.tripStartDate, 'YYYY-MM-DD').add((activity.activityDay - 1), 'days').format('ddd ')}</div>
                                                                <div className="text-center text_main_blue" style={{ font: screenSize > 1024 ? "36px/40px Futura Hv BT" : "18px/20px Futura Hv BT", }}>{moment(trip.tripStartDate, 'YYYY-MM-DD').add((activity.activityDay - 1), 'days').format('D')}</div>
                                                                <div className="text-center text_main_black" style={{ font: screenSize > 1024 ? "14px/14px Futura Hv BT" : "7px/7px Futura Hv BT", }}>{moment(trip.tripStartDate, 'YYYY-MM-DD').add((activity.activityDay - 1), 'days').format('MMM')}</div>
                                                            </div>
                                                        } */}

                                                        {<div className="text-center text_main_black publicTimelineDays"
                                                            style={{marginTop:!activity?.activityEstabID? idx === 0?12:60:0,  marginLeft: -50, font: screenSize > 1024 ? "18px/20px Futura Hv BT" : "14px/14px Futura Hv BT", }}>

                                                            {dayDate}

                                                        </div>}



                                                    </div>
                                                    : ""}
                                                {/* {day_container_display === 1 && idx === 0 && <div className="text_main_black "
                                                    style={{
                                                        font: "14px/20px Futura Hv BT",
                                                        textAlign: "left", padding: 10
                                                    }}>

                                                    {"Day " + activity.activityDay}

                                                </div>} */}


                                                {activity?.activityEstabID ? <div style={{ width: is_mobile ? "100%" : "93%", display: is_mobile ? "block" : "flex", }}>
                                                    {time_container_dispaly === 1 ? <div className="day_time" style={{ left: "-120px", width: "85px" }}>{time_container}</div> : ""}
                                                    <div className="d-inline-block" style={{
                                                        width: "100%", maxWidth: 560,
                                                        backgroundColor: "#e8e8e8"
                                                    }}>
                                                        <Image
                                                            src={image_boxing(activity.activityImage, "500", "265")}
                                                            alt="activity" style={{ width: "100%", objectFit: "cover", objectPosition: "center", height: "100%", maxHeight: height }} /></div>
                                                    <div className="d-inline-block" style={{ border: "1px solid #E8E8E8", verticalAlign: "middle", width: "100%", height: height, maxHeight: height, flexGrow: "1", position: "relative" }}>
                                                        <div className="futura_md_bt_14_16_black margin_top_10 box_1" style={{ paddingLeft: "10px" }}>{activity.activityNeighborhood}</div>
                                                        <EstablishmentName
                                                            establishmentId={activity.activityEstabID}
                                                            establishmentName={activity.activityName}
                                                            titleClass={"box_1"}
                                                            titleStyle={{ cursor: 'pointer', font: "24px/43px Futura Hv BT", textAlign: "left", color: "var(--frameBlack)", paddingLeft: "10px", }}
                                                        />


                                                        {activity.activityStarRating !== "0.0" && <div className="d-flex timeline_rating_color pl-2 btn_rounded" style={{ paddingLeft: "10px" }}>
                                                            <Rating name="timeline_rating" precision={0.1} max={5} value={parseFloat(activity.activityStarRating)} disabled={true} />
                                                            <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5">({activity.activityStarRating})</div>
                                                        </div>}

                                                        {/* {<div style={{ paddingLeft: "10px" }}>
                                                        {activity.activityDescription !== undefined &&
                                                            <EstablishmentDescription description={activity.activityDescription}
                                                                />}
                                                        </div>} */}

                                                        <div style={{ paddingLeft: "10px" }}>
                                                            {activity.activityDescription !== undefined &&
                                                                <EstablishmentDescription bottom={1} description={activity.activityDescription} boxDescription={"box_2"} fontDescription={"14px/19px Futura Lt BT"} colorDescription={"var(--mainGrey)"}
                                                                    is_mobile={is_mobile}
                                                                    textLength={500}
                                                                    height={is_mobile ? "160px" : "160px"}
                                                                    maxHeight={is_mobile ? "160px" : "160px"}
                                                                // height={"102px"} 
                                                                />}
                                                        </div>


                                                        <div className="pl-2 text-left box_1" style={{
                                                            paddingTop: "5px",
                                                            position: "absolute", bottom: "14px", zIndex: "2",
                                                        }}>
                                                            {activity.activitySubTypes !== undefined && activity.activitySubTypes.length > 0 &&
                                                                activity.activitySubTypes.map((element, idx) => {
                                                                    return <div key={idx}
                                                                        className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style">
                                                                        {element.subtypeName}</div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div> :

                                                    <div
                                                        style={{
                                                            font: "18px/20px Futura Md BT",
                                                            textAlign: "left", padding: 10,
                                                            color: "var(--frameBlack)"
                                                        }}>

                                                        No activites yet for this day
                                                        <span onClick={() => addActivity(activity)} style={{
                                                            font: "16px/20px Futura Lt BT",
                                                            color: "var(--mainGreen)",
                                                            cursor: "pointer"
                                                        }}> (add activities) </span>

                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        : ""}
                                </React.Fragment>
                            )
                        })
                    }
                </div>
                {!openTimeline && tripDestinations[destinationNumberIndex]?.destinationDays?.length > 3 && <div className="content_text_container_bottom_white"
                    style={{
                        msTransform: tripDestinations[destinationNumberIndex]?.destinationDays !== undefined && tripDestinations[destinationNumberIndex]?.destinationDays?.length > 3 ? "translateY(-30px)" : "",
                        transform: tripDestinations[destinationNumberIndex]?.destinationDays !== undefined && tripDestinations[destinationNumberIndex]?.destinationDays?.length > 3 ? "translateY(-30px)" : ""
                    }} ></div>}

                {tripDestinations[destinationNumberIndex]?.destinationDays !== undefined && tripDestinations[destinationNumberIndex]?.destinationDays?.length > 3 ?
                    openTimeline ?
                        <React.Fragment>
                            <div className="text-center">
                                <div className="d-inline-flex p-2 justify-content-center futura_md_bt_13_16_green" style={{ cursor: "pointer" }} onClick={() => setClose()}>
                                    <div className="m-0 p-0 futura_md_bt_16_19_green" >See Less</div>
                                    <UpArrowIcon fill={"#19BC9B"} style={{ marginTop: "3px", marginLeft: "8px" }} />
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="text-center">
                                <div className="d-inline-flex p-2 justify-content-center futura_md_bt_13_16_green" style={{ cursor: "pointer" }} onClick={() => setOpenTimeline(true)}>
                                    <div className="m-0 p-0 futura_md_bt_16_19_green" >See All</div>
                                    <DownArrowIcon fill={"#19BC9B"} style={{ marginTop: "3px", marginLeft: "8px" }} />
                                </div>

                            </div>
                        </React.Fragment>

                    : ""
                }


            </div>
            {/* return */}
            {destinationFlights && destinationFlights?.length > 0 &&

                <div>
                    {destinationFlights.map((flight, index) => {

                        const isReturnFlight = hasSavedFlights && timelineFlights.flightInfo.length > 1 && index === timelineFlights.flightInfo.length - 1;

                        if (flight?.return_leg_from_trip_destination_id && flight?.flight_json) {
                            const isRoundTrip = flight?.search_type === "round_trip";
                            return (<div key={index}>
                                <div style={{}}>

                                    <MuverTripFlight flight={flight}
                                        sliceIndex={isRoundTrip ? 1 : null}
                                        destinationFlights={destinationFlights}
                                        tripDestinations={tripDestinations}
                                        currentDestIndex={destinationNumberIndex} />
                                </div>
                            </div>)
                        }
                    })
                    }
                </div>
            }

        </div >
    )

}

const mapStateToProps = (state) => {
    return {
        trip: state.PublicTrip.trip,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        is_mobile: state.Setting.is_mobile,
        uuid: state.Member.authModal.uuid,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        logo_width: state.Setting.appMenu.logo.width,
    }
}

const mapDispatchToProps = {
}


const PublicTripTimeline = connect(mapStateToProps, mapDispatchToProps)(PublicTripTimeline_)
export default PublicTripTimeline
