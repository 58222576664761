import "./BookTrip.css";

import * as Actions from '../../../redux/actions'

import { PropTypes } from "prop-types"
import React from 'react'
import { RightArrowIcon } from '../Common/SVGBank';
import { connect, useDispatch, useSelector } from 'react-redux'
import { member_area_router, URL_REPLACE } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"
import { environment } from "../../../Environments/environment";
import { GetAsyncHelper } from "../../../redux/interfaces/types";
import { Backdrop, makeStyles, Popover } from '@material-ui/core'
import { toggleOneclick } from "../FeaturePage/personalizedTrip/personalizedTrip.slice";
import { clearFlightBooking, resetFlightPage, updateFlightData } from "../FlightsPage/services/Flights.slice";
import { getFlightsListThunk } from "../FlightsPage/services/Flights.thunks";
import moment from "moment";
import { clearHotelBooking } from "../BookingPage/PaymentPage/payment.slice";
import { encryptID } from "../HotelPage/hotelServices";


/* save trip component */
const BookTrip_ = ({ copyPublicTrip, setSwitchContainer, trip, uuid, alertBar, cost, copyTripId, setCopyTrip }) => {
    const [tripCost, setCost] = React.useState();
    const [anchorElCost, setAnchorEl] = React.useState(null);
    const openCost = Boolean(anchorElCost);
    const formatCost = tripCost?.cost ? Number(tripCost?.cost).toFixed(2)?.toString().split(".") : null;
    const wholePriceCost = formatCost ? formatCost[0] : null;
    const centsCost = formatCost ? formatCost[1] : null;
    const oneclick = useSelector(state => state.PersonalizedTrip.oneclick);
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const activeTrip = useSelector(state => state.FormBank.TimelineWizard.activeTrip);
    const activeTripData = useSelector(state =>state.ActiveTrip.trip)
    const activeTripId = activeTrip?.tripId;
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const numTravellers = activeTripData?.adults? Number(activeTripData?.adults):0 + activeTripData?.children? Number(activeTripData?.children):0
    
    const dispatch = useDispatch()

    const history = useHistory()
    const handlerSaveTrip = () => {
        if (uuid !== undefined && uuid !== "") {
            copyPublicTrip(trip.tripID, trip.tripName, uuid)
        } else {
            this.props.setOpenAuthModal(true)
            alertBar("warning", "Please Sign In / Sign Up")
        }
    }

    const getTripCost = async () => {

        const url_active_trip_get_cost = environment.apiUrl + "timeline/getTripCost/en/" + (uuid ? uuid : "TMPUUID") + "?tripId=" + trip.tripID + "&getTripCost"

        const responseTripCost = await GetAsyncHelper(url_active_trip_get_cost)
        if (responseTripCost.statusText === "OK") {
            if(responseTripCost.data.result){
                setCost(responseTripCost.data.result)
            }
           
        } else {
        }
    }

    React.useEffect(() => {
        if (trip?.tripID) {
            getTripCost()
        }
    }, [trip?.tripID, uuid])




    React.useEffect(() => {
        if (copyTripId !== undefined && copyTripId !== "") {

            setSwitchContainer(member_area_router.destination)
            history.push("/destination-explore/copy/trip/" + copyTripId)
            setCopyTrip("")
        }
    }, [copyTripId])

    const handleClickCost = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseCost = () => {
        setAnchorEl(null);
    };


    const bookTrip = async () => {

        clearBooking() ///any previous one
        
        const hotel = oneclick?.hotel;
        const flight = oneclick?.flight;

        if (hotel) {
            dispatch(toggleOneclick(true))
            const city = hotel?.cityName ? hotel.cityName + ', ' : ""
            const state = hotel?.stateName ? hotel.stateName + ', ' : ""
            const country = hotel?.countryName ? hotel.countryName : ""
            const where = city + state + country;

            let url = ""
            if (hotel?.pricelineCityId) {
                const encryptedId =  encryptID( hotel?.pricelineHotelId)
                url = "/hotel-" + hotel?.name.replace(URL_REPLACE, "_") + "-" + encryptedId
                    + "?city_id=" + hotel?.pricelineCityId
                    + "&check_in=" + hotel?.check_in
                    + "&check_out=" + hotel?.check_out
                    + "&rooms=" + 1
                    + "&guests=" + 1
                    + "&hotels=" + hotel?.pricelineHotelId
                    + "&city_lat=" + hotel?.latitude
                    + "&city_lng=" + hotel?.longitude
                    + "&where=" + (where.replace(URL_REPLACE, "_"))

                if (hotel?.ppn_bundle) {
                    url += "&ppn_bundle=" + hotel?.ppn_bundle
                }
            }
            else {

                url = "/hotel-search?city_id=" + null
                    + "&check_in=" + hotel?.check_in
                    + "&check_out=" + hotel?.check_out
                    + "&rooms=" + 1
                    + "&guests=" + 1
                    + "&hotels=" + hotel?.pricelineHotelId
                    + "&city_lat=" + hotel?.latitude
                    + "&city_lng=" + hotel?.longitude
                    + "&where=" + (where.replace(URL_REPLACE, "_"))
            }


            history.push(url.toLowerCase());
        }

        else if(flight){
            searchFlight()
        }

    }

    const clearBooking = ()=>{
        dispatch(clearFlightBooking())
        dispatch(clearHotelBooking())
    }


    const searchFlight = () => {
        const flight = oneclick?.flight;
        const flights = [
            {
                "airportFrom": {
                    "country": "",
                    "coordinate": "",
                    "city": flight?.departure_city,
                    "latitude": "",
                    "city_code": flight?.departure_iata,
                    "type": "main",
                    "country_code": "",
                    "cityid_t": "",
                    "state_name": "",
                    "cityid_ppn": "",
                    "rank": "",
                    "cityid_air": "",
                    "state_code": "",
                    "longitude": "",
                    "score": "",
                    "iata": flight?.departure_iata,
                    "airport": "All Airports"
                },
                "airportTo": {
                    "country": "",
                    "coordinate": "",
                    "city": flight?.arrival_city,
                    "latitude": "",
                    "city_code": flight?.arrival_iata,
                    "type": "main",
                    "country_code": "",
                    "cityid_t": "",
                    "state_name": "",
                    "cityid_ppn": "",
                    "rank": "",
                    "cityid_air": "",
                    "state_code": "",
                    "longitude": "",
                    "score": "",
                    "iata": flight?.arrival_iata,
                    "airport": "All Airports"
                },
                "departureDateFormat": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                "departureDate": flight?.departure_date ? new Date(moment(flight?.departure_date).format("YYYY-MM-DD 00:00")) : new Date().toDateString(),
                "availableSelectDate": new Date().toDateString(),
                "positionId": "d-0"
            }]
            const numPassengers = flight?.flight_json?.itinerary_data?.length > 0 &&
            flight?.flight_json?.itinerary_data[0]?.price_details?.number_of_passengers;
        const dataToBookingFlights = {
            flightMode: flight?.search_type || 'one_way',
            cabinClass: 'economy',
            numberAdult: numPassengers,
            numberChild: '0',
            flights: flights,
            currency: fullCurrency

        }
      

        dispatch(updateFlightData(dataToBookingFlights))
        dispatch(resetFlightPage())
        dispatch(getFlightsListThunk(dataToBookingFlights))

        history.push('/flight/search')
    }


    return (
        <div className="margin_top_20 d-flex justify-content-end">
            <div>
               {tripCost !== null && tripCost?.cost && <div className="futura_lt_bt_21_21_black"
               style={{   font: "22px/0px Futura LT BT",}}>{ "Trip cost"}
                    <span onClick={handleClickCost}
                        style={{
                            fontSize: 14, marginLeft: 10,
                            font: "14px/50px Futura LT BT",
                            color: '#C7C7C7',
                            cursor: "pointer"
                        }}>
                        details
                    </span>
                </div>}
                {tripCost !== null && tripCost?.cost && <Popover
                    id={"tripCost tripPage"}
                    open={openCost}
                    anchorEl={anchorElCost}
                    onClose={handleCloseCost}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: -10,
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: { borderRadius: 0, width: 180, padding: 10 }
                    }}
                    elevation={2}

                >
                    <div style={{ font: "12px/19px Futura Lt BT", color: '#274674' }}>

                    <div style={{ display: 'flex', font: "12px/19px Futura Md BT", color: '#274674', marginBottom: 1 }}>
                            <div style={{}}>
                                Price for {numTravellers} traveller(s)
                            </div>
                         
                        </div>
                        <div style={{ display: 'flex', font: "12px/19px Futura Lt BT", color: '#274674', marginBottom: 1 }}>
                            <div style={{ flex: '0 0 50%' }}>
                                Flight(s):
                            </div>
                            <div style={{ flex: '0 0 50%', textAlign: 'right' }}>
                                {tripCost?.currency_symbol + "" + Number(tripCost?.costForFlight).toFixed(2)}
                            </div>
                        </div>

                        <div style={{ display: 'flex', font: "12px/19px Futura Lt BT", color: '#274674', marginBottom: 1 }}>
                            <div style={{ flex: '0 0 50%' }}>
                                Hotel(s):
                            </div>
                            <div style={{ flex: '0 0 50%', textAlign: 'right' }}>
                                {tripCost.currency_symbol + "" + Number(tripCost?.costForHotel).toFixed(2)}
                            </div>
                        </div>

                        <div style={{ display: 'flex', font: "12px/19px Futura Lt BT", color: '#274674', marginBottom: 1 }}>
                            <div style={{ flex: '0 0 50%' }}>
                                Activities:
                            </div>
                            <div style={{ flex: '0 0 50%', textAlign: 'right' }}>
                                {tripCost.currency_symbol + "" + Number(tripCost?.costForActivities).toFixed(2)}
                            </div>
                        </div>
                        <hr style={{ borderTop: "dashed 1px #274674", marginBottom: 4, marginTop: 2 }} />

                        <div style={{ display: 'flex', font: "12px/19px Futura Lt BT", color: '#274674', marginTop: -3 }}>
                            <div style={{ flex: '0 0 50%' }}>
                                TOTAL:
                            </div>
                            <div style={{ flex: '0 0 50%', textAlign: 'right' }}>
                                {tripCost?.currency + tripCost.currency_symbol + "" + Number(tripCost.cost).toFixed(2)}
                            </div>
                        </div>


                    </div>
                </Popover>}
                {tripCost !== null && tripCost?.cost && <div className="futura_hv_bt_36_43_black tripCost" ><span
                    style={{
                        font: "22px/50px Futura Md BT",
                    }} className="tripCurrency">{tripCost?.currency + tripCost?.currency_symbol + " "}</span>
                    {wholePriceCost}.<sup style={{ fontSize: '50%', top: '-0.7em' }}>{centsCost}</sup></div>}
            </div>
            <div>



                <div className="bg_green top_bar_container" onClick={bookTrip} 
                style={{ cursor: "pointer" }}>
                    <div className="futura_md_bt_24_10_white vertical_center left_50" >
                        Book this trip  <RightArrowIcon />
                    </div>
                </div>
            </div>
        </div>
    )
}


BookTrip_.propTypes = {
    trip: PropTypes.object.isRequired,
    tripId: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
    return {
        trip: state.PublicTrip.trip,
        cost: state.PublicTrip.tripCost.cost,
        tripId: state.PublicTrip.tripId,
        copyTripId: state.PublicTrip.copyTripId,
        uuid: state.Member.authModal.uuid,
        lang: state.Setting.lang,
    }
};

const mapDispatchToProps = {
    copyPublicTrip: Actions.copyPublicTrip,
    setOpenAuthModal: Actions.setOpenAuthModal,
    alertBar: Actions.alertBar,
    setCopyTrip: Actions.setCopyTrip,
    setSwitchContainer: Actions.setSwitchContainer,
}

export const BookTrip = connect(mapStateToProps, mapDispatchToProps)(BookTrip_)
