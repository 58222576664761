import "./MuverTrip.css"

import * as Actions from '../../../redux/actions'

import React from 'react'
import { URL_REPLACE } from "../Common/RegExValidate"
import { connect, useDispatch } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"
import { Button } from "react-bootstrap"
import { getBookingFromText } from "./BookingUpload.services"
import { CircularProgress } from "@material-ui/core"


/* top bar component */
const BookingUpload_ = ({uuid, setOpenTimeline, getPublicTrip, logo_width, switch_container, activeTrip, uploadTripImage, getSelectedTripInformation, trip_public, trip_private, lang, screenSize, from, uuidPublic, uuidPrivate, tripImage }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [postContent, setPostContent] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);


    const getBooking = async()=>{
        setSubmitting(true)
       await  getBookingFromText({
            uuid:uuid,
            userContent:postContent
        })
        setSubmitting(false)
    }


    return (
        <div className="" style={{
            background: "lightgrey",
            padding: 30
        }}  >
            <div style={{
                font: "18px/16px Futura Md BT",
            }}>
                Copy and paste email confirmation text
            </div>

            <textarea className="uploadBookingArea" style={{
                background: "white",
                border: "none",
                resize: "none",
                padding: 40,
                width: "100%",
                marginTop: 20,
                borderRadius: 20,
                font: "16px/16px Futura Lt BT",
            }} rows="10" onChange={e => setPostContent(e.target.value)}
                placeholder="Copy and Paste Here">

            </textarea>

            <div style={{ width: "100%", textAlign: "right" }}>
                
                <Button onClick={getBooking} style={{ font: "16px/16px Futura Md BT", borderRadius: 15, marginTop: 15, width: "150px", height: "40px", color: "white", backgroundColor: "var(--mainBlue)" }}>
                {submitting ? <CircularProgress size={14} color="inherit" />:<span style={{font: "16px/16px Futura Md BT"}}>SUBMIT</span>}</Button>

            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        trip_public: state.PublicTrip.trip,
        trip_private: state.Member.memberArea.selectedTrip,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
		uuid: state.Member.authModal.uuid,
        tripId: state.PublicTrip.tripId,
        lang: state.Setting.lang,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        uuidPublic: state.Social.member_uuid,
        uuidPrivate: state.Member.authModal.uuid,
        logo_width: state.Setting.appMenu.logo.width,

        tripImage: state.Member.memberArea.tripImage,
        switch_container: state.Member.switch_container,
    }
}

const mapDispatchToProps = {
    getPublicTrip: Actions.getPublicTrip,
    getSelectedTripInformation: Actions.getSelectedTripInformation,
    setTripToTimeline: Actions.setTripToTimeline,
    uploadTripImage: Actions.uploadTripImage,
}
export const BookingUpload = connect(mapStateToProps, mapDispatchToProps)(BookingUpload_)
