import BarsSubType from './BarsSubType'
import Clips from "./Clips"
import Establishments from './PricelineEstablishments'
import Features from './Features'
import { Progress } from '../Common/Progress'
import React from 'react'
import HotelSearchFormEmbedded from "../Common/BookingForm/HotelSearchFormEmbedded"
import { SearchBarHotels } from './SearchBarHotels'
import { SortingBarHotels } from './SortingBarHotels'
import BarsFilterType from './BarsFilterType'
import ClipsFilter from './ClipsFilter'
import TripSection from '../Common/Trip/TripSection.jsx'
import Trips from './Trips'
import Types from './Types'
import { connect } from 'react-redux'
import { features_router } from "../Common/RegExValidate"
import { member_area_router } from "../Common/RegExValidate"
import { BarsIcon } from "../Common/SVGBank"

const HotelListingsListContainer_ = ({hotelSearch, lazyLoading,lang, establishments,is_mobile, switch_container, noProgress, subtypes, hotelSearchForm,
                                       suggestedTrips, switchFeatureContainer, timelineStatus, app_menu_height, }) => {
    let width;
    let marginLeft;
    let padding_r = 19.5

    if (!isNaN(is_mobile)) {
        width = is_mobile ? '97%' : '67%';
        padding_r = is_mobile ? "3%" : '0%'
        marginLeft = is_mobile ? '3%' :  timelineStatus ? '31%' :  '15.5%';
    }

 
    

    return (<React.Fragment>

            <div>
                <div style={{
                        position: "relative",
                        width: width,
                        marginLeft: marginLeft
                 }}>
                    <div style={{
                        zIndex: "4",
                        backgroundColor: "var(--mainWhite)",
                        paddingTop:is_mobile? 50:15,
                        paddingRight: padding_r,
                    }}>
                        {hotelSearchForm.check_in!=="" &&
                            <div className="background-img-row">
                            <div className={"booking_form_container"}>
                                { <HotelSearchFormEmbedded embedForm="results"/> }
                            </div>
                        </div>}
                                          
                    </div>
                    <div style={{
                        paddingBottom: "10px",
                        position: is_mobile ? "" : "sticky",
                        top: is_mobile ? "" : (-Math.ceil(-app_menu_height / 2)),
                        backgroundColor: "#FFFFFF",
                        paddingRight: padding_r,
                        zIndex: "3",
                    }}>
                        {/*<Types lang={lang} /> */}                        
                        {   subtypes !== undefined 
                                      && subtypes?.length > 0 && <div className="d-flex margin_top_10">
                            {/* {subtypes !== undefined && subtypes.length > 0 ? <BarsFilterType />
                                : <div style={{
                                    paddingTop: "20px", zIndex: "1", marginRight: "5px"
                                }}>
                                  
                                  </div>} */}

                              
                                <BarsFilterType />
                                    
                            {subtypes !== undefined && subtypes.length > 0 ? <ClipsFilter /> : <div style={{ flexGrow: "1" }}></div>}
                            {!is_mobile  && <SearchBarHotels />}
                            {!is_mobile  && <SortingBarHotels />}
                        </div>}
                        {is_mobile  && <SearchBarHotels />}
                        {is_mobile  && <SortingBarHotels />}

                    </div>
                    <div style={{
                        paddingBottom: "10px",
                    }}>
                        <Establishments />
                        {switchFeatureContainer.featureId === features_router.trips.featureId && <Trips />}
                        {!noProgress && <Progress />}
                    </div>
                </div>
            </div>
    </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
    timelineStatus: state.FormBank.TimelineWizard.status,
    is_mobile: state.Setting.is_mobile,
    lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    noProgress: state.FormBank.HotelSearch.lazyLoading.noProgress,
    suggestedTrips: state.Destination.destination.suggestedTrips,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    screenHeight: state.Setting.screenSize.height,
    logo_width: state.Setting.appMenu.logo.width,
    establishments: state.FormBank.HotelSearch.establishments,
    subtypes: state.FormBank.HotelSearch.subtypes,
    lang: state.Setting.lang,
    switch_container: state.Member.switch_container,
    hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
    hotelSearch: state.FormBank.HotelSearch
}}

const mapDispatchToProps = {
}

const HotelListingsListContainer = connect(mapStateToProps, mapDispatchToProps)(HotelListingsListContainer_)
export default HotelListingsListContainer
