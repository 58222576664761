import { Backdrop, Modal } from '@material-ui/core';
import React from 'react';
import { CameraIcon, RightArrowIcon, TimesIcon } from '../Common/SVGBank';
import { connect, useDispatch, useSelector } from 'react-redux'
import { AddCalendarModalUseStyles, PersonalizedModalUseStyles } from '../FeaturePage/personalizedTrip/personalizedTripStyles';
import Google from '../../../assets/images/calendar/google.png';
import Iphone from '../../../assets/images/calendar/iphone.png';
import Outlook from '../../../assets/images/calendar/outlook.png';
import { GetICSUrl } from './Calendar.services';

 const AddToCalendarContainer_ = ({ isMobile, open, closeModal, dataId, dataKey }) => {
    const dispatch = useDispatch()
    const classes = AddCalendarModalUseStyles();
    const { uuid } = useSelector(state => state.Member.authModal);
    const trip = useSelector(state => state.PublicTrip.trip) 
    const tripID = trip?.tripID;
    const email =trip?.muver?.muverEmail;
    const [icsLink, setIcsLink] = React.useState("");

    React.useEffect(()=>{

        const fetchURL=async()=>{
            setIcsLink("Fetching Link...")
            const url  = await GetICSUrl(email,uuid,tripID);
            if(url){
                setIcsLink(url)
            }
            else{
                setIcsLink("Calendar Link not found")
            }
  
        }
        if(uuid && tripID && email){
            fetchURL()
        }
    },[uuid,tripID,email])

    return (
        <div>
            <Modal
                open={open}
                onClose={closeModal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 1000,
                }}
            >
                <div style={{
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -40%)'

                }} className={classes.paper}>


                    <div className="d-flex" style={{
                        position:"sticky",
                        top:0
                    }}>
                        <div style={{
                            font: "24px/24px Futura Hv BT",
                            color: "#191919",
                            paddingLeft: '0px',
                            paddingBottom: '30px',
                            paddingTop: '15px',
                            width: "90%",
                            marginRight: "5%",
                            lineHeight: '32px'
                        }}>

                        </div>
                        <div
                            style={{
                                cursor: "pointer",

                            }}
                            onClick={closeModal}><TimesIcon style={{ marginTop: "15px" }} width={'20'} height={'20'} /></div>

                    </div>


                    <div className="" style={{ marginTop: 10, padding: 30 }}>
                        <div style={{
                            font: "16px/18px Futura Md BT",

                        }}>
                            Add your trips to your favourite calendar(s). It's easy, fast and you will only have to do it once.
                            <div style={{ marginTop: 10 }}>
                                Subscribe to your müvCalendar from other calendars and your trips will update automatically.
                            </div>
                            <div style={{ marginTop: 20 }}>
                                Add this URL in your Google, iPhone or Outlook calendars:
                            </div>

                            <div style={{ marginTop: 20, color: "var(--mainGreen)" }}>
                                {icsLink}
                            </div>
                        </div>

                        <div style={{
                            marginTop: 20, font: "16px/18px Futura Md BT",
                        }}>
                            Google Calendar
                        </div>
                        <div style={{
                            marginTop: 5, font: "16px/18px Futura Lt BT",
                            display: "block"
                        }}>
                            <ol>
                                <li>Log into your Google Calendar.</li>
                                <li>On the left side of the page, find "Other Calendars".</li>
                                <li>Click on + on the right and select "From URL".</li>
                                <li>Enter the url above in the input field and click on "Add calendar"</li>
                            </ol>
                            
                        </div>
                        <img src={Google} style={{ height: 120 }} alt="google image" />
                      

                        <div style={{
                            marginTop: 20, font: "16px/18px Futura Md BT",
                        }}>
                            Iphone Calendar
                        </div>
                        <div style={{
                            marginTop: 5, font: "16px/18px Futura Lt BT",
                        }}>
                            <ol>
                                <li>On your iPhone open "Settings" then "Calendar".</li>
                                <li>Click on "Accounts" then "Add Account".</li>
                                <li>On "Add Account" page click on "Other" then "Add Subscribed Calendar".</li>
                                <li>Type or paste the URL above into the text field and tap "Next".</li>
                            </ol>


                        </div>
                        <img src={Iphone} style={{ height: 120 }} alt="iphone image" />


                        <div style={{
                            marginTop: 20, font: "16px/18px Futura Md BT",
                        }}>
                            Outlook Calendar
                        </div>
                        <div style={{
                            marginTop: 5, font: "16px/18px Futura Lt BT",
                        }}>
                            <ol>
                                <li>Open Outlook, go to Calendars</li>
                                <li>In the navigation pane, select Open calendar.</li>
                                <li>Select From Internet</li>
                                <li>Enter the above URL and click ok</li>
                            </ol>
                        </div>
                        <img src={Outlook} style={{ height: 120 }} alt="outlook image" />


                    </div>



                </div>

            </Modal>
        </div>)

}

const mapStateToProps = (state) => ({
    trip: state.PublicTrip.trip,
    is_mobile: state.Setting.is_mobile,
    authModal: state.Member.authModal,
    lang: state.Setting.lang,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    uuidPublic: state.Social.member_uuid,
    uuidPrivate: state.Member.authModal.uuid,

})


const AddToCalendarContainer = connect(mapStateToProps, {})(AddToCalendarContainer_)
export default AddToCalendarContainer
